import React from 'react';
import './header_underline_animation.css';

function HeaderUnderlineAnimation(props) {

    let underline_color = props.color;
    console.log(underline_color);
  return (
    <>
    {/* <span className = {`underlined-heading ${props.textColor} ${props.lineColor} `} > {props.text} </span> */}
    <h2 className = {`underlined-heading ${props.textColor} ${props.lineColor} `} > {props.text} </h2>

    </>
  );
}

export default HeaderUnderlineAnimation;
