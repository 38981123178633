import React from 'react';
import './button_animation.css';


function ButtonAnimation(props) {

  return (
    <>
    <span className={`button-animation ${props.bgColor} ${props.color}`} > {props.text} </span>
    </>
  );
}

export default ButtonAnimation;
