import '../App.css';
import React,{useLayoutEffect} from 'react';
import './services.css';
import {Container , Row, Col,Card} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import { HighlightsAnimation } from '../Courses/AerialRoboticsWorkshop';
import surveyVector from '../images/webp/survey_vector.webp'; 
import { motion } from "framer-motion";
import droneVector  from '../images/webp/droneVector.gif';

import roadSurveyHeader from '../images/webp/road-survey-header.gif';
import roadSurveyVector from '../images/webp/road-survey-vector.gif';
import roadConstructionVector from '../images/webp/road-construction-vector.webp'
// import headerVector from '../images/webp/survey.webp';
import '../Courses/courses.css';
import RoadSurveyPhoto from '../images/webp/road-survey.webp';
import roadSurveyProcess from '../images/webp/road-video-survey-process.jpg';

function HeroSection() {
    return(
        <>
            <header className="hero-header road-survey-header">
                <img src={roadSurveyVector} alt='survey-header-img' className='survey-vector-image fit-image'/>
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'>Road Survey - Drone Geotagged Video </h1>
                <h4>Efficient Progress Monitoring with Drone Technology</h4>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Get in Touch' color='black'/></NavLink>
                </div>
            </header>
        </>
    )
}

function OverviewSection(){
    return(
        <>
            <Row className='overview-row'>
                    <Col xs={12} lg={6} >
                    {/* <div>
                    <img src={surveyVector} className='fit-image' alt='surverying-img' />
                    </div> */}
                    <div className="d-flex">
                        <img src={roadConstructionVector} alt="survey-vector" className="contact-img"></img>
                        <motion.div className="drone-img"
                        animate={{
                        x: ["0vw","-10vw","-30vw","-20vw","-5vw"], 
                        y: ["-5vh","-12vh","3vh","20vh", "0vh"],  
                            }}
                        transition={{
                        duration: 10,
                        ease: "easeInOut",
                        times: [0, 0.3,0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1
                        }}>

                        <img src={droneVector} alt="drone-vector" className="drone-img"></img><br />
                        </motion.div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <h4 className='content p-1 road-survey-h2'> Drone based Geo-tagged Road Survey Video </h4>
                        <p className='content p-3'> Geotagged video road surveying using drones is a highly effective method for monitoring construction progress. By capturing high-resolution videos from above, drones provide a comprehensive view of the construction site and enable project managers to track progress in real-time. Geo-tagging is a process of embedding geographical identification metadata (coordinates such as longitudes, latitudes etc.),and also chainage number for each video frame. In road surveys, geo-tagged video acts as a report to document the construction progress with accurate geospatial information. 
                        </p>
                        </Col>
                </Row>
                <Row>
                <p className='content p-3'>
                Monitoring greenfield areas that do not have road accessibility can also be achieved quickly and easily. 
                This allows construction teams to plan and design construction projects with greater accuracy, taking into account the unique features and characteristics of the land. It also enables construction teams to identify potential challenges early on and take proactive measures to mitigate them.
                This technology allows for efficient progress tracking, ensuring that construction projects stay on schedule and within budget. With the ability to quickly identify areas that need attention or have fallen behind schedule, construction teams can take corrective action sooner rather than later, ultimately leading to a more successful project outcome.
                </p>

                <p className='content p-3'> 
                In order to enhance transparency, uniformity and leverage latest technology,   <a href={('https://pib.gov.in/PressReleasePage.aspx?PRID=1727537')}>The National Highways Authority of India (NHAI)</a> has made drone surveys mandatory for highway projects. Under this requirement, drone surveys are conducted at various stages of the highway project, including pre-construction, during construction, and post-construction phases.
                The use of drones for highway surveys offers several advantages over traditional surveying methods. Firstly, it allows for more comprehensive and accurate data collection, as drones can capture high-resolution images and videos of the entire project site. This enables project managers to make more informed decisions and adjustments throughout the project lifecycle.
                Additionally, drone surveys are faster and more cost-effective than traditional surveying methods. They require fewer personnel and can cover large areas in a short amount of time, reducing the time and cost required for data collection.
                By making drone surveys mandatory for highway projects, NHAI is ensuring that construction projects are completed with greater accuracy and efficiency.
                </p>
                </Row>
        </>
    )
}

function KeyBenefits(){
    return(
        <>
            <p className='heading industry-head'><HeaderUnderlineAnimation text='Key Benefits'/> </p>
                    <Row>
                        <Col xs={12} lg={4} className='survey-key-benefits'> 
                            <HighlightsAnimation num='01' text="High Accuracy"/>
                        </Col>   
                        <Col xs={12} lg={4} className='survey-key-benefits'> 
                            <HighlightsAnimation num='02' text="Track Progress"/>
                        </Col>   
                        <Col xs={12} lg={4} className='survey-key-benefits'> 
                            <HighlightsAnimation num='03' text="Reproducibilty"/>
                        </Col>   
                        
                    </Row>
                    <Row>
                    <Col xs={12} lg={4} className='survey-key-benefits'> 
                        <HighlightsAnimation num='04' text="Time Effecient"/>
                    </Col> 
                    <Col xs={12} lg={4} className='survey-key-benefits'> 
                        <HighlightsAnimation num='05' text="Economical"/>
                    </Col> 
                    <Col xs={12} lg={4} className='survey-key-benefits'> 
                        <HighlightsAnimation num='06' text="Improved Safety"/>
                    </Col> 
                    </Row>
                    <Row>
                    <Col xs={12} lg={6} className='survey-key-benefits'> 
                        <HighlightsAnimation num='07' text="Informed Decisions"/>
                    </Col>
                    <Col xs={12} lg={6} className='survey-key-benefits'> 
                        <HighlightsAnimation num='08' text="Enhanced Transparnecy"/>
                    </Col>
                    </Row>
        </>
    )
}


function ReferenceVideo(){
    return(
        <>
        <div className='courses-gallery-div' style={{paddingTop: "50px"}}>
            <p className='heading'><HeaderUnderlineAnimation text='Reference Video'/></p>
            <iframe width="90%" height="350" src="https://www.youtube.com/embed/yGzGtxw-M3o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        </>
    )
}
function Process(){
    return(
        <>
        <div className='courses-gallery-div' style={{paddingTop: "50px"}}>
            <p className='heading'><HeaderUnderlineAnimation text='PROCESS'/></p>
            <img src={roadSurveyProcess} alt='road-survey-video-process' className='process-img'/>
        </div>
        </>
         )
        }
function RoadSurvey() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className='about-component'>
                <HeroSection />
                <Container>
                    <OverviewSection />
                    <Process />
                    <KeyBenefits />
                    <ReferenceVideo />
                    <HomeContactSection />
                </Container>
            </div>
        </>
    )}

export default RoadSurvey;


