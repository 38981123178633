import React from 'react';
import Swiper from 'react-id-swiper';
import '../node_modules/swiper/css/swiper.css';
import './testimonials.css';
import {Card} from 'react-bootstrap';
import {Container, Col, Row} from 'react-bootstrap';
import QuotesImg from './images/webp/quotes.webp';
import GaurangShetty from './images/testimonial_images/gaurang.webp';
import Parveen from './images/testimonial_images/parveen.webp';
import Vamakshi from './images/testimonial_images/vamakshi.webp';
import Minal from './images/testimonial_images/minal.webp';
import Aadi from './images/testimonial_images/aadi.webp';
import GlendaKwek from './images/testimonial_images/GlendaKwek.webp';

function Testimonials() {

  const params = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 30,
      stretch: 0,
      depth: 200,
      modifier: 1,
      slideShadows : true,
  }
}



let testinomialData = [
  {   
      key : '1',
      img : GaurangShetty,
      name : 'Gaurang Shetty',
      desg : 'Chief Innovation Catalyst, RIIDL',
      testimonial : "Drone School India is one of the best experiential learning programs, to learn about the future of mobility and get accustomed to it's potential."

  },
  {   
    key : '2',
    img : GlendaKwek,
    name : 'Glenda Kwek',
    desg : 'Student, Aerial Filming',
    testimonial : "Excellent and patient drone training by Dhruvin and Dhaarna in Mumbai. Comprehensive syllabus for both theory and practical for the Aerial Filming course. Learnt a lot in just a few days and am looking forward to using the skills that I've learnt.",

},
  {   
    key : '3',
    img : Aadi,
    name : 'Aadi Dhiren Chedda',
    desg : 'Student, The Somaiya School',
    testimonial : "I loved the workshop very much. It was a very exciting experience. I loved coding and flying the drone manually. The best of all was building. Thank you for teaching me about drones. Thank you!"

},
{
  key : '4',
  img : Parveen,
  name : 'Parveen Shaikh',
  desg : 'Principal, The Somaiya School',
  testimonial : 'Aerial Robotics Courses by Drone School India,is conducted by young passionate engineers who have made it their mission to introduce young students to what Drones can do.Its an interactive,hands on learning session, where students not only fly drones but make their own drones.Its a fun way to introduce young students to the technology of the future.'

},
{      
  key : '5',
  img : Vamakshi,
  name : 'Vamakshi Dhoria',
  desg : 'Founder, Tripsy Traveller',
  testimonial : "Team DSI is extremely professional and hardworking. They helped me create an amazing travel film complete with aerial cinematography and creative shots! Their team is meticulous and patient and suggested some amazing shooting ideas. I strongly vouch for their talent and would like to on-board them as our aerial filming partners for our future travel fims."

},
  
  {
    key : '6',
    img : Minal,
    name : 'Minal Thakkar,',
    desg : 'Parent',
    testimonial : 'Drone School India conducts one of the best drone workshops. My son, Rishi Thakkar has attended the workshop of flying-Autonomous and Manual. Dhruvin Dodhiya Sir is one of the best and I recommend him to all my friends. Dharna Mam who taught coding was also very knowledgeable and my son, Rishi was pretty comfortable. After these workshops, Rishi has started developing interest in mechanical aspects of engineering, his concentration level has improved and his creative skills have blossomed.'
  }
]
  return (
    <>

    <div className='testimonial-main-div'>
    <Container>
    <div className='homeContactDiv'>
    <Row>
    <Col className='homeContactText mr-auto' xs={12} lg={12} >
      <h2 className='lighterHeading'>Reviews</h2>
      <h2 className='whiteHeading'> Our Motivation To Deliver The Best.  </h2><br />

      <Swiper {...params}>

      {
        testinomialData.map((testimonial,index) => (
          
          <Card  className="testimonial-card h-100" style={{ backgroundColor: '#eee', width:'300px'}}>
            <img className='testimonialPhoto' src={testimonial.img} alt="testimonial" />
              <Card.Body className='testimonial-card-body'>        
                <div className='cardText'>
                <Card.Subtitle className="mb-2 text-muted">{testimonial.name}</Card.Subtitle>
                <Card.Subtitle style={{color:'orange'}}>{testimonial.desg}</Card.Subtitle>
                <br />
                <Card.Text className="justify-content">{testimonial.testimonial}</Card.Text>
                </div>
                <Card.Img  src={QuotesImg} alt="Card image cap"  className='card-body-img'/>
            </Card.Body>
          </Card>
          ))} 
  
 </Swiper>



    </Col>
    </Row>
    </div>
    </Container>

</div>
  </>
  );
}

export default Testimonials;