import '../App.css';
import React,{useLayoutEffect} from 'react';
import './courses.css';
import {Container , Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import LightDarkHeading from '../LightDarkHeading/LightDarkHeading';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import {NavLink} from 'react-router-dom';
import {PhotoGallery, VideoGallery} from '../Gallery/AerialGallery';
import galleryImg1 from '../images/webp/bootcamp-img1.webp'; 
import galleryImg2 from '../images/webp/bootcamp-img2.webp'; 
import galleryImg3 from '../images/webp/bootcamp-img3.webp'; 
import galleryImg4 from '../images/webp/bootcamp-img-4.webp'; 
import videoThumbnail1 from '../images/webp/videothumbnailb1.webp';
import videoThumbnail2 from '../images/webp/videothumbnailb2.webp';
import pluto from '../images/webp/pluto.webp';
import DroneVector from '../images/webp/droneVector.gif';
// import video4 from '../videos/GalleryVideos/video1.mp4';
import video1 from '../videos/bootcamp-video1.mp4';
import video2 from '../videos/bootcamp-video2.mp4';
import codingGif from '../images/coding-gif.gif';
// import bootcampHeader from '../images/bootcamp-header.gif';
import bootcampdronegif from '../images/bootcamp-drone.gif';
// import { motion } from "framer-motion";
import DroneCodingBootcampBrochure from '../images/DroneCodingBootcamp-Brochure.pdf'

function Highlights(props) {
    return(
        <>
            <Col xs={12} lg={3}>
            <div className='highlights-component'>
                <span className='highlights-num'>{props.num}</span>
                <h2 className='highlights-text'> {props.text}</h2>
            </div>
            </Col>
        </>
        )
    }

function CourseOverviewSection(){
    return(
        <>
<Row>
        <Col xs={12} lg={6}>
        <div className='d-flex'>
            <img className='fit-image' src={codingGif} alt='coding-gif'/>
            <img className='pluto-vector' src={pluto} alt='pluto-drone-vector'/>
        </div>
        </Col>
        <Col xs={12} lg={6}>
        <div className='course-overview-div'>
        <h2 className='heading'><HeaderUnderlineAnimation text='COURSE OVERVIEW' lineColor='orange'></HeaderUnderlineAnimation></h2>
         <p className= 'text-justify'>Are you a drone enthusiast wanting to explore and tinker with a drone’s code? Do you want to make a drone fly according to your whim? Do you wish to integrate a drone into your robotics project?
<br /><br />
Then this course is for you! Our team of expert instructors train you in all that there is to code a drone. You will be trained in industry softwares such as ROS and open source drone platform Pluto. They will ensure that once you complete this course, you will be able to communicate with all the sensors and control drone's movements the way you want.  Explore our syllabus to know more!


</p>
        </div>
        </Col>
        </Row>
        </>
    )
}

function Syllabus(){
    return(
        <>
        <div className='syllabus-div'>
            <Row>
                <Col xs={12} lg={6}>
                <div className='syllabus-header'>
                    <LightDarkHeading lightHeading='Syllabus' darkHeading='What You Will Learn in this Course.'/>
                </div>
                </Col>
                <Col xs={12} lg={6}>
                <img src={DroneVector} alt='droneVector' className='syllabus-section-drone'/>
                <div className='syllabus-modules'>
                <h2><HeaderUnderlineAnimation text='Level 1' lineColor='Orange' /></h2>
                    <br />
                    <ul>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to UAVs' lineColor='blue' /></h2>
                            <p>Basic drone terminologies, types of drones, applications of drones, categories of drones. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Rules and Regulations' lineColor='blue' /></h2>
                            <p>Drone flying zones, latest rules and regulations, altitude restrictions, UIN, digital sky platform, do’s and don'ts.</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Fundamentals of Multirotor UAV' lineColor='blue' /></h2>
                            <p>Drone maneuvering and controls terminologies, motor configuration, DOF, basic dynamics, forces and torque.  </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='General Block Diagram' lineColor='blue' /></h2>
                            <p>Understanding FC, ESC, battery, GPS, IMU, telemetry, sensors, propellers, motors and their connections with each other.  </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Assembling Nano Drone' lineColor='blue' /></h2>
                            <p>Introduction to Pluto drone-kit, build your nano drone, sensor calibration, pre-flight checks.</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Drone Flying with Instructor ' lineColor='blue' /></h2>
                            <p>Instructions for drone flying, introduction to drone flying app, hands-on drone flying in different flying modes.</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to Cygnus' lineColor='blue' /></h2>
                            <p>Cygnus software installation, introduction to Magis firmware, APIs for Pluto drone, Pluto Pilot code structure. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Practicals on Cygnus & Pluto' lineColor='blue' /></h2>
                            <p>Program the logic in Cygnus IDE and perform experiments using onboard and external sensors on Pluto drone.</p>
                        </li>
                        <h2><HeaderUnderlineAnimation text='Level 2' lineColor='Orange' /></h2>
                    <br />
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to ROS' lineColor='blue' /></h2>
                            <p>Introduction to ROS, Architecture, features, philosophy, environment, packages, master, topics, nodes, messages, services, catkin build systems, building and sourcing packages. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Practicals on ROS & Pluto' lineColor='blue' /></h2>
                            <p>Communication between talker and listner nodes, sending commands to drone over topics, keyboard controlled drone, obtaining live drone camera feed, computer vision based projects. </p>
                        </li>
                        
                    </ul>
                </div>
                </Col>
            </Row>
            </div>


        {/* <div class="foo">
        <Row>
        <Col lg={3}>
            <div class="bar">Header Bar</div>
        </Col>
        <Col lg={9}>
            <div class="scrollable_content">
                <div class="someOther">Line 1</div>
                <div class="someOther">Line 2</div>
                <div class="someOther">Line 3</div>
                <div class="someOther">Line 4</div>
                <div class="someOther">Line 5</div>
                <div class="someOther">Line 6</div>
                <div class="someOther">Line 7</div>
                <div class="someOther">Line 8</div>
            </div>
        </Col>
        </Row>
        </div> */}
        </>
    )
}

function Prerequisities(){
    return(
        <>
        <div className='pre-requisities-div'>
            <Row>
                <Col xs={12} lg={6}>
                    <div className='p-4'>
                        <h2 className='text-left heading'><HeaderUnderlineAnimation text='Pre-Requisites' /></h2>
                        <p className='text-left'>For a better understanding of the course, we encourage you to have a basic understaning of these topics. </p>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className='pre-requisities-content border'>
                    <Row>
                    <Col>
                        <ul className='text-left pre-requisites-list'>
                            <li>- Knowledge of Ubuntu OS</li>
                            <li>-  Understanding of Sensors</li>
                            {/* <li>- Lorem Ipsum</li> */}
                        </ul>
                    </Col>
                    <Col>
                        <ul className='text-left pre-requisites-list'>
                            <li>-  Python, C</li>
                            <li>-  Basic Laws of Physics</li>
                            {/* <li>- Lorem Ipsum</li> */}
                        </ul>
                    </Col>
                    </Row>

                    </div>
                </Col>
            </Row>
        </div>
        </>
    )
}


function CoursesPhotoGallery(){
    let photos = [galleryImg1, galleryImg3, galleryImg2, galleryImg4];
    return(
        <>
        <div className='courses-gallery-div'>
            <h1 className='heading'><HeaderUnderlineAnimation text='Photo Gallery'/></h1>
            <PhotoGallery photoArray = {photos} />
        </div>
        </>
    )
}

function CoursesVideoGallery(){

    let videos =  [
        {   id :1,
            src: video1,
            poster: videoThumbnail1,
            lg : 6
        },
        {   id :2,
            src : video2,
            poster: videoThumbnail2,
            lg : 6
        },
        // {   id :3,
        //     src : "https://www.youtube.com/embed/FRxSh0Ba0dU",
        //     // poster: videoThumbnail2,
        //     lg : 6
        // },
    ]


    // let videos = [video1, video1];
    return(
        <>
        <div className='courses-gallery-div'>
            <h1 className='heading'><HeaderUnderlineAnimation text='Video Gallery'/></h1>
            <VideoGallery videoArray = {videos} />
        </div>
        </>
    )
}

function HeroSection() {
    return(
        <>
            <header className="hero-header bootcamp-header">
        
                <img src={bootcampdronegif} alt='vt-header-img workshop-header-img' className='book-image'/>
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'> Drone Coding Bootcamp</h1>
                <h4>Experential advance computing on nano drones</h4>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Enroll Now' color='black'/></NavLink>
                {/* <br/> */}
                <a href={DroneCodingBootcampBrochure} download><ButtonAnimation text='Brochure' color='black'/></a>
                </div>
            </header>
        </>
    )
}

function DroneBootcamp() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className='courses-component'>
            <HeroSection />
                <Container>
                    <div className='highlights-div'>
                    <Row>
                        <Highlights num='01' text="Learn"/>
                        <Highlights num='02' text="Build"/>
                        <Highlights num='03' text="Code"/>
                        <Highlights num='04' text="Fly"/>
                    </Row>
                    </div>

                    <CourseOverviewSection />
                    <Prerequisities />
                    <Syllabus />
                    <CoursesPhotoGallery />
                    <CoursesVideoGallery />
                    <HomeContactSection />
                
                </Container>
            </div>
        </>
    )}

export default DroneBootcamp;