import './App.css';
import React from 'react';
import './home.css';
import {NavLink} from 'react-router-dom';
import Dronacharya from './images/webp/dronacharya.webp';
import {Container, Col, Row} from 'react-bootstrap';
import ButtonAnimation from './ButtonAnimation/ButtonAnimation';
import droneVector  from './images/webp/droneVector.gif';
import { motion } from "framer-motion";


function HomeContactSection() {
  return (
    <>
    <Container>
    <div className='homeContactDiv'>
    <Row>
    <Col className='homeContactText mr-auto' xs={12} lg={6} >
      <h2 className='lightHeading'>Want a Higher View ?</h2>
      <h2 className='darkHeading'> Talk To Our Drone-Acharyas </h2><br />
      <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Contact Us'/></NavLink>
    </Col>
    <Col xs={12} lg={6}  className="d-inline-block">
      {/* <img src={Dronacharya} alt="dronacharya" className="fitImage dronacharya-image"></img> */}
      <div className="d-flex">
            <img src={Dronacharya} alt="Dronacharya" className="contact-img"></img>
            <motion.div className="drone-img"
              animate={{
              x: ["0vw","0vw","-40vw","-5vw","0vw"], 
              y: ["30vh","-15vh","-15vh", "-10vh","30vh"],  
                  }}
            transition={{
              duration: 10,
              ease: "easeInOut",
              times: [0, 0.3, 0.5 ,0.8,1],
              loop: Infinity,
              repeatDelay: 1
            }}>

            <img src={droneVector} alt="drone-vector" className="drone-img"></img><br />
            </motion.div>
            </div>
    </Col>
    </Row>
    </div>
    </Container>
    </>
  );
}

export default HomeContactSection;
