import './App.css';
import React from 'react';
import './contact.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import SendIcon from '@material-ui/icons/Send';

function ContactForm() {
  return (
    <>
    <div className="contact-card">
      <div className="contact-container">
      <br />
    <h3> We'd Love To Hear From You! </h3><hr />

    <form id="contact-form" action="https://formspree.io/f/xvovbejo" name="contact-form" method="POST">
        
      <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                  <input type="text" id="firstname" name="firstname" class="form-control" placeholder="First Name" required />
              </div>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                  <input type="text" id="lastname" name="lastname" class="form-control" placeholder="Last Name" required />
              </div>
          </div>
      </div>
                        
                        
      <div class="row">
      <div class="col-md-12">
          <div class="form-group">
              <input type="email" id="email" name="email" class="form-control" aria-describedby="emailHelp" placeholder="Enter email" required />
            </div>
      </div>
      <div class="col-md-12">
          <div class="form-group">
              <input type="tel" id="number" name="number" class="form-control" placeholder="Mobile Number" required />
          </div>
      </div>
  </div>
                        
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <input type="text" id="city" name="city" class="form-control" placeholder="Your City" required />
            </div>
        </div>
    </div>
                        
    <div class="row">
        <div class="form-group col-md-12">
          <select id="inputState" class="form-control" id="interest" name="interest" >
            <option selected> Book </option>
            <option> Aerial Cinematography </option>
            <option> Training </option>
            <option> Aerial 360 Virtual Tour </option>
            <option> Real Estate </option>
            <option> OTHER </option>
          </select>
        </div>
    </div>
                        
    <div class="row">
        <div class="col-md-12">
            <textarea type="text" id="message" name="message" class="form-control" placeholder="Your Message" required ></textarea>
        </div>
    </div>
    <br />             
    <button type="submit" className="contact-btn"  onclick="document.getElementById('contact-form').submit();"> <SendIcon style={{ transform: "rotate(-45deg)", marginTop: -10 }} />  Send Message</button>
    <br />
    <br />
  </form>
    </div>
    </div>
    </>
  );
}

export default ContactForm;
