import './App.css';
import React from 'react';
import './home.css';
import {NavLink} from 'react-router-dom';
import homeAboutDrone from './images/webp/homeAboutDrone.webp';
import {Col, Row} from 'react-bootstrap';
import ButtonAnimation from './ButtonAnimation/ButtonAnimation';
import HeaderUnderlineAnimation from './HeaderUnderlineAnimation/HeaderUnderlineAnimation';

function HomeAboutSection() {
  return (
    <>
    <div className='homeAboutDiv'>
    <Row>
    <Col  xs={12} lg={6} className='aboutImg'>
      <img className='img-fluid' src={homeAboutDrone} alt='drone'></img>
    </Col>
    <Col  xs={12} lg={6} className='aboutText'>
      <h2> We're a  Drone based<HeaderUnderlineAnimation text="EduTrain " lineColor="orange" textColor="white"> </HeaderUnderlineAnimation> and <br />
      <HeaderUnderlineAnimation text="Technology Solutions" lineColor="blue" textColor="white"> </HeaderUnderlineAnimation> company.</h2><br />
      <NavLink to="/about" className='text-decoration-none'><ButtonAnimation  style={{zIndex:'2'}} text='About Us' color='white'/></NavLink>
    </Col>
    </Row>
    </div>
    </>
  );
}

export default HomeAboutSection;
