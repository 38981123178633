import '../App.css';
import React,{useLayoutEffect} from 'react';
import './services.css';
import {Container , Row, Col} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import LightDarkHeading from '../LightDarkHeading/LightDarkHeading';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import VirtualTourVector from '../images/webp/virtual-tour.webp';
import vrIcon from '../images/webp/vr-icon.webp';
import CuratedIcon from '../images/webp/curated-icon.webp';
import GoogleStreetView from '../images/webp/Google-Streetview-for-Business-Google-Icon.webp';
import MultiDevices from '../images/webp/all-devices-icon.webp';
import {IndustriesSection} from './AerialCinematography';
import '../Courses/courses.css';
import vrGif from '../images/webp/vr-gif.webp';
import EduInstitues from '../images/webp/educational-institues.webp';
import smartCity from '../images/webp/smartCities.webp';
import IndustrialPlant from '../images/webp/industrial-plant.webp';
import tourismCard from '../images/webp/travel_tourism.webp';
import Resorts from '../images/webp/resorts.webp';
import RealEstate from '../images/webp/Real-Estate-Card-Image.webp'


let vtIndustriesArray = [
    {   
        id : '1',
        src : tourismCard,
        title : 'Tourism and Travel',
        info : 'Create a mesmerising effect on tourists as the camera pans across the architecture, natural wonders and the history of your state. The mix of colours that flash across the screen creating the need for travelers and adventurers to visit your state’s beauty.'
    },
    {
        id : '2',
        src : RealEstate,
        title : 'Real Estate',
        info : 'Help the prospective buyer in identifying their needs such as the amenities, proximity from major roads, hospitals, stations, etc. Showcase the lavish view that can be seen from their windows or balconies and help the buyer envision their life in their new home.'
    },
    {
        id : '3',
        src : EduInstitues,
        title : 'Educational Institutes',
        info : 'Inspire the next generation of dreamers as they live their lessons and achieve a greater understanding of their subjects. Whether it is intricate as biology and geography or engaging as language and sports, a spark is ignited that leads to greater thinking.'
    },
    {
        id : '4',
        src : Resorts,
        title : 'Hotels and Resorts',
        info : 'Help your guests envision their perfect holiday. Stimulate a daydream of the perfect weekend getaway by lounging by the pool, going on an adventure in the glades, or having the perfect candle light dinner with a view.'
    },
    {
        id : '5',
        src : smartCity,
        title : 'Smart Cities',
        info : 'Layout the map for your residents and help them navigate through their new surroundings. Highlight the nearest recreation centres, dispensaries, educational institutes, transport etc. to make everyday living efficient, cleaner and safer.'
    },
    {
        id : '6',
        src : IndustrialPlant,
        title : 'Industrial Plants',
        info : 'Layout the blueprint for your next plant. Make your business more efficient by identifying highways, proximity from the closest towns and cities, and natural features surrounding your facility to create the ideal plant with environmentally friendly disposal.'
    }

]

let highlightsArray = [{icon : MultiDevices, text : 'Adaptive UI'},{icon : vrIcon, text : 'VR Compatible'},{icon : CuratedIcon, text : 'Curated Experience'},{icon : GoogleStreetView, text : 'Google Compatible'},];


function HeroSection() {
    return(
        <>
            <header className="hero-header">
                <header className="hero-header vt-header">
                <img src={vrGif} alt='vr-header-img' className='vr-vector-image fit-image'/>
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'> 360° Virtual Tours </h1>
                <h4>Teleportation At Your Fingertips </h4>
                <br />
                <NavLink to="/aerial-360-virtual-tour-gallery" className='text-decoration-none'><ButtonAnimation text='Gallery' color='black'/></NavLink>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='get in touch' color='black'/></NavLink>
                </div>
            </header>

            </header>
        </>
    )
}

function OverviewSection(){
    return(
        <>
            <Row className='overview-row'>
                    <Col xs={12} lg={7} >
                    <div>
                    {/* <iframe width="100%" height="400" allowFullscreen='true' style={{"border-style":"none"}} src={"https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/9rdNSZe.jpg&title=CST%20Crawford%20Market&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120"}></iframe> */}
                    <iframe width="100%" height="400" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/crawford-market-360-vt/"}></iframe>
                    </div>
                    </Col>
                    <Col xs={12} lg={5}>
                        <p className='content p-3'> 360° Degree Virtual Aerial Tours help you conceptualise your needs with the click of a button. 
                        Using the latest high resolution cameras, we create a bespoke aerial view map showcasing the key features that help you and your business connect with your consumers. 
                        Whether you wish to survey the amenities and proximity from major roads, hospitals, stations, etc. of your next home or masterfully layout the blueprint for your next manufacturing or storage facility, 
                        it helps you understand the topography of the area and identify all the discrepancies that may hinder your plan.
                        Virtual Tours help you picture your next holiday or the view from your sea facing window of your new home.</p>
                    </Col>
                </Row>
        </>
    )
}

function Highlights(){
    return(
        <>
        <Row>
        {
            highlightsArray.map((highlight,index) => (
                <>
                <Col xs={12} lg={3}>
                    <div className='vt-highlights-component'>
                        <img className='highlights-icon' src={highlight.icon} alt='highlights-icon'/><br /><br />
                        <span className='highlights-text vt-highlights-text'> {highlight.text}</span>
                    </div>
                </Col>
            </>
            ))}
        </Row>
        </>
    )
}


function ExampleVT() {
    return(
        <>
        <div className='vt-examples-section'>
            <Row>
            <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
                    <div className='vt-card'>
                        <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/prabhadevi-360-day-evening-night-transition/"}></iframe>
                        <h4 className='vt-text' ><HeaderUnderlineAnimation text='Day-Evening-Night' lineColor='blue'/></h4>
                        <h2> 360° Aerial Panorama of Prabhadevi, Mumbai</h2>
                    </div>
                </Col>
                <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
                    <div className='vt-card'>
                    <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/69xqika.jpg%3F1&title=Industrial%20Warehousing%20Hub%2C%20Bhiwandi&autoLoad=true&autoRotate=1&pitch=-20&yaw=-60"}></iframe>
                    {/* <h4 className='vt-text'>Pandavkada Waterfalls </h4> */}
                    
                    <h4 className='vt-text' ><HeaderUnderlineAnimation text='Warehouse Hub' lineColor='blue'/></h4>
                    <h2> 360° Aerial Panorama of Warehousing Hub, Bhiwandi</h2></div>
                </Col>
                
            </Row>
            </div>
        </>
    )
}

function ExploreGallery (){
    return(
        <>
        <div className='explore-gallery-section'>
            <Row className='explore-gallery-content'>
                <Col xs={12} lg={6}>
                    <img src={VirtualTourVector} className='fit-image' alt='virtual-tour-vector' />
                </Col>
                <Col xs={12} lg={6} >
                    <LightDarkHeading lightHeading='360° VT Gallery' darkHeading='Explore & Experience Places Virtually'/>
                    <ButtonAnimation  text='GALLERY' />
                </Col>
            </Row>
        </div>
        </>
    )
}

function Benefits(){
    return(
        <>
        <div className='vt-benefits-section'>
        <Row>
        <Col className='homeContactText mr-auto' xs={12} lg={12} >
            <h2 className='lightHeading'> Why 360° Panoramas?</h2>
            <h2 className='darkHeading'> Benefits of 360° Virtual Tours </h2><br /> 
        </Col>
        </Row>
        <div className='benefits-list'>
        <ul>
            <li>Ease of visiting the location from the comforts of your mobile device or laptop.</li>
            <li>An in-depth analysis of the topography and identification of key features of the area for infrastructure development.</li>
            <li>Easy identifying facilities within a property such as an adventure resort and easy mapping.</li>
            <li>Strategic marketing tool to help attract customers in the form of a Virtual Tour or in the form of a short film.</li>
            <li>Creates a positive impression on consumers who visit your portal.</li>
        </ul>
        </div>
        </div>
        </>
    )
}

function VirtualTours() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className='about-component'>
                <HeroSection />
                <Container>
                    <Highlights />
                    <OverviewSection />
                    <Benefits />
                    <IndustriesSection  industries = {vtIndustriesArray}/>
                    <ExampleVT />
                    <ExploreGallery />
                    <HomeContactSection />
                </Container>
            </div>
        </>
    )}

export default VirtualTours;


