// import 'venobox/venobox/venobox.css';

// import $ from 'jquery';
import React,{useEffect, useLayoutEffect } from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '../App.css';
import '../home.css';
import './gallery.css';

import 'baguettebox.js/dist/baguetteBox.css';
import baguetteBox from 'baguettebox.js/dist/baguetteBox.js';
import baguetteBoxVideo from "./baguetteBoxVideo";
import BhandardaraLake from '../images/GalleryPhotos/BhandardaraLake.webp';
import trainandtunnel from '../images/GalleryPhotos/trainandtunnel.webp';
import tunnels from '../images/GalleryPhotos/tunnels.webp';
import gorai from '../images/GalleryPhotos/gorai.webp';
import cityscape from '../images/GalleryPhotos/cityscape.webp';
import RatnadurgJetty from '../images/GalleryPhotos/RatnadurgJetty.webp';
import fields from '../images/GalleryPhotos/fields.webp';
import JaigadFort from '../images/GalleryPhotos/JaigadFort.webp';
import SindhudurgFort from '../images/GalleryPhotos/SindhudurgFort.webp';
// import AlibaugFort from '../images/GalleryPhotos/AlibaugFort.webp';
import MajiwadaJn from '../images/GalleryPhotos/majiwada.webp';
import Upvan1 from '../images/GalleryPhotos/upvan-1.webp';
import Upvan2 from '../images/GalleryPhotos/upvan-2.webp';

// import aerialvideo1 from '../videos/GalleryVideos/aerial-video1.mp4';
// import aerialvideo2 from '../videos/GalleryVideos/aerial-video2.mp4';
// import aerialvideo3 from '../videos/GalleryVideos/aerial-video3.mp4';
// import aerialvideo4 from '../videos/GalleryVideos/aerial-video4.mp4'
// import showreel from '../videos/GalleryVideos/dsixant.mp4';
// import wollywood from '../videos/GalleryVideos/wollywood.mp4';
// import Nirmaldhara from '../videos/GalleryVideos/nirmaldhara-real-estate-video.mp4';
// import showreelThumbnail from '../images/webp/showreel-thumbnail.webp';
// import wollywoodThumbnail from '../images/webp/Real-Estate-Card-Image.webp';
// import showreelThumbnail4 from '../images/webp/showreel4-thumbnail.webp';
// import showreelThumbnail3 from '../images/webp/showreel3-thumbnail.webp';
// import showreelThumbnail2 from '../images/webp/showreel2-thumbnail.webp';
// import showreelThumbnail1 from '../images/webp/showreel1-thumbnail.webp';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';



function PhotoGallery(props){
  useEffect(() => {
    baguetteBox.run('.gallery');
  });

  let photos = props.photoArray;

  
  return(
    <>
        <div className='gallery'>
        <Row>
            {
            photos.map((photo, index) => (
            <>
            <Col xs={6} lg={6} className='p-2 fit-image'>
              <a class="fit-image img-fluid" href={photo}><img className='fit-image' src={photo} alt='gallery' /></a>
            </Col>
            </>
        ))}
      </Row>  
      </div>
    </>
  )
}


function PhotoGalleryCustomized(props){
  useEffect(() => {
    baguetteBox.run('.gallery');
  });

  let photos = props.photoArray;

  
  return(
    <>
        <div className='gallery'>
        <Row>
            {
            photos.map((photo, index) => (
            <>
            <Col xs={12} lg={photo.lg} className='p-2 fit-image'>
              <a class="fit-image img-fluid" href={photo.img}><img className='fit-image' src={photo.img} alt={photo.alt}/></a>
            </Col>
            </>
        ))}
      </Row>  
      </div>
    </>
  )
}



function VideoGallery(props){
  useEffect(() => {
    baguetteBoxVideo.run('.video-gallery');
  });

  let videos = props.videoArray;
  
  return(
    <>
    <div className='video-gallery'>
    <Row>
      {
        videos.map((myvideo, index) => (
        <>
        <Col xs={12} lg={myvideo.lg}>
          <div className='video-div resp-container'>
            <a href={myvideo.src}>
            {/* <video className='fit-image' controls controlsList="nodownload" poster={myvideo.poster}>
              <source src={myvideo.src} />
            </video> */}
            <iframe className='iframeClass' src={myvideo.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay &mute=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe>
            </a>   
          </div>
      </Col>
        </>
        ))}
      </Row>
      </div>
    </>
  )
}

function AerialPhotoGallery() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

    let aerialPhotos = [
      { id : 1,
        img :  SindhudurgFort,
        lg : 12,
        alt : 'SindhudurgFort'
      },
      { id : 2,
        img : fields,
        lg : 6,
        alt : 'fields'
      },
      { id : 3,
        img : BhandardaraLake,
        lg : 6,
        alt : 'BhandardaraLake'
      },
      { id : 4,
        img : Upvan1,
        lg : 6,
        alt : 'Upvan Arts Festival Aerial 1'
      },
      { id : 5,
        img : Upvan2,
        lg : 6,
        alt : 'Upvan Arts Festival Aerial 1'
      },
      { id : 6,
        img : JaigadFort,
        lg : 6,
        alt : 'JaigadFort'
      },
      { id : 7,
        img :  RatnadurgJetty,
        lg : 6,
        alt : 'RatnadurgJetty'
      },
      // { id : 5,
      //   img :  AlibaugFort,
      //   lg : 6,
      //   alt : 'AlibaugFort'
      // },
      { id : 8,
        img :  MajiwadaJn,
        lg : 6,
        alt : 'MajiwadaJn'
      },
      { id : 9,
        img : gorai,
        lg : 6,
        alt : 'gorai'
      }  ,
      { id : 10,
        img : tunnels,
        lg : 6,
        alt : 'tunnels'
      },
      { id : 11,
        img : trainandtunnel,
        lg : 6,
        alt : 'trainandtunnel'
      },

      
      { id : 12,
        img : cityscape,
        lg : 12,
        alt : 'cityscape'
      },
      
     ];

  return (
    <>
    <div className='my-gallery-component'>
    <Container>
      <h2 className='gallery-heading'><HeaderUnderlineAnimation text='Aerial Photos' textColor='white'/></h2>
      <PhotoGalleryCustomized photoArray = {aerialPhotos} />
    </Container>
    </div>
    </>

  );
}

function AerialVideoGallery() {  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  
  let videos =  [
    {
      id : 1,
      src : "https://www.youtube.com/embed/lu3i5tp9mL4",
      // poster : showreelThumbnail,
      lg : 12,
    },
    {
      id: 2,
      src: "https://www.youtube.com/embed/lGPAmXcaQQs",
      // poster: showreelThumbnail,
      lg: 6,
    },
    {
      id: 3,
      src: "https://www.youtube.com/embed/3nFpsJZ1XRo",
      // poster: showreelThumbnail,
      lg: 6,
    },
    {
      id: 4,
      src: "https://www.youtube.com/embed/RLBO3NJnkGY",
      // poster: showreelThumbnail,
      lg: 6,
    },
    {
      id: 5,
      src: "https://www.youtube.com/embed/hzH0Y2-exZY",
      // poster: showreelThumbnail,
      lg: 6,
    },
]

return(
  <>
  <div className='my-gallery-component'>
    <Container>
      <h2 className='gallery-heading'><HeaderUnderlineAnimation text='Aerial Showreels' textColor='white'/></h2>
      <VideoGallery videoArray = {videos} />  
    </Container>
  </div>
  </>
)}


function Aerial360VirtualTourGallery() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  
  return(
    <>

    {/* <div class="top-bar fixed-top nav-bar">
      <ul>
          <li><a href="#aerial-vt" class="nav-link nav-connect">AERIAL</a></li>
          <li><a href="#interior-vt" class="nav-link nav-connect">INTERIOR</a></li>
      </ul>
    </div> */}
    <div className='my-360-gallery-component'>
      
      <Container>
        <h1 className='gallery-heading'><HeaderUnderlineAnimation text='360° Virtual Tours' textColor='white'/></h1>

      {/* <Row id='aerial-vt'> */}
      <Row>
      <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
        <div className='vt-card'>
            <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/prabhadevi-360-day-evening-night-transition/"}></iframe>
            <h4 className='vt-text' ><HeaderUnderlineAnimation text='Day-Evening-Night' lineColor='blue'/></h4>
            <h2> 360° Aerial Panorama of Prabhadevi, Mumbai</h2>
        </div>
      </Col>
      <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
        <div className='vt-card'>
            <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/brick-village-360-vt/"}></iframe>
            <h4 className='vt-text' ><HeaderUnderlineAnimation text='360° VT of Saj By The Lake' lineColor='blue'/></h4>
            {/* <h2> 360° Aerial Panorama of Prabhadevi, Mumbai</h2> */}
        </div>
      </Col>

      <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
      <div className='vt-card'>
      <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/crawford-market-360-vt/"}></iframe>
        <h4 className='vt-text' ><HeaderUnderlineAnimation text='Crawford Market' lineColor='blue'/></h4>
        <h2> 360° Aerial Panorama of CST Crawford Market</h2>
        </div>
      </Col>

      {/* <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
      <div className='vt-card'>
        <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120"}></iframe>
        <h4 className='vt-text' ><HeaderUnderlineAnimation text='Pandavkada Waterfalls' lineColor='blue' /></h4>
        <h2> 360° Aerial Panorama of Pandavkada Waterfalls</h2>
        </div>
      </Col> */}
{/* 
      <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
      <div className='vt-card'>
        <iframe width="100%" height="400" allowFullscreen='true' style={{"border-style":"none"}} src={"https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/69xqika.jpg%3F1&title=Industrial%20Warehousing%20Hub%2C%20Bhiwandi&autoLoad=true&autoRotate=1&pitch=-20&yaw=-60"}></iframe>        
        <h4 className='vt-text' ><HeaderUnderlineAnimation text='Warehouse Hub' lineColor='blue'/></h4>
        <h2> 360° Aerial Panorama of Warehousing Hub, Bhiwandi</h2>
        </div>
      </Col> */}
      </Row>

      {/* <h1 className='gallery-heading'><HeaderUnderlineAnimation text='360° Interior Virtual Tours' textColor='white'/></h1> */}

      {/* <Row id='interior-vt'> */}
      {/* <Row> */}
      {/* <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
        <div className='vt-card'>
            <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/precon-virtual-tour/index.html"}></iframe>
            <h4 className='vt-text' ><HeaderUnderlineAnimation text='Precon Automation & Systems' lineColor='blue'/></h4>
        </div>
      </Col> */}

      {/* <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
      <div className='vt-card'>
      <iframe width="100%" height="500" allowfullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/thevacayvans-360-virtual-tour/index.html"}></iframe>
        <h4 className='vt-text' ><HeaderUnderlineAnimation text='The Vacay Van' lineColor='blue'/></h4>
        </div>
      </Col> */}


      {/* <Col xs={12} lg={12} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
      <div className='vt-card'>
        <iframe width="100%" height="500" allowFullscreen='true' style={{"border-style":"none"}} src={"https://droneschoolindia.com/nirmal-dhara-3bhk-virtual-tour/index.html"}></iframe>
        <h4 className='vt-text' ><HeaderUnderlineAnimation text='Nirmaldhara 3BHK' lineColor='blue' /></h4>
        </div>
      </Col> */}
      {/* </Row> */}
      </Container>


      {/* <Container id='vt-container'> 

      <h2 className='gallery-heading'><HeaderUnderlineAnimation text='360° Virtual Tours' textColor='white'/></h2>


        <Row>
          <Col xs={12} lg={4} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
            <div className='vt-card'>

            <a className="fit-image img-fluid" href='https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120'><img className='fit-image' src={img3} /></a>   
            <h5>Pandavkada Waterfalls</h5>
            <p> 360° Aerial Panorama of Pandavkada Waterfalls</p>

          </div>
        </Col>

        <Col xs={12} lg={4} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
            <div className='vt-card'>

            <a className="fit-image img-fluid" href='https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120'><img className='fit-image' src={img3} /></a>   
            <h5>Pandavkada Waterfalls</h5>
            <p> 360° Aerial Panorama of Pandavkada Waterfalls</p>

          </div>
        </Col>

        <Col xs={12} lg={4} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
            <div className='vt-card'>

            <a className="fit-image img-fluid" href='https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120'><img className='fit-image' src={img3} /></a>   
            <h5>Pandavkada Waterfalls</h5>
            <p> 360° Aerial Panorama of Pandavkada Waterfalls </p>

          </div>
        </Col>

        <Col xs={12} lg={4} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
            <div className='vt-card'>

            <a className="fit-image img-fluid" href='https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120'><img className='fit-image' src={img3} /></a>   
            <h5>Pandavkada Waterfalls</h5>
            <p> 360° Aerial Panorama of Pandavkada Waterfalls </p>

          </div>
        </Col>

        <Col xs={12} lg={4} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
            <div className='vt-card'>

            <a className="fit-image img-fluid" href='https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120'><img className='fit-image' src={img3} /></a>   
            <h5>Pandavkada Waterfalls</h5>
            <p> 360° Aerial Panorama of Pandavkada Waterfalls </p>

          </div>
        </Col>

        <Col xs={12} lg={4} style={{'margin-bottom' : '80px', 'padding' : '0px 30px'}}>
            <div className='vt-card'>

            <a className="fit-image img-fluid" href='https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/uMlbiZf.jpg&title=Pandavkada%20Waterfalls&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=80&pitch=-15&hfov=120'><img className='fit-image' src={img3} /></a>   
            <h5>Pandavkada Waterfalls</h5>            
            <p> 360° Aerial Panorama of Pandavkada Waterfalls </p>

          </div>
        </Col>
        </Row>
      </Container> */}
    </div>
    </>
  )
  }

export default AerialPhotoGallery;
export {AerialVideoGallery, Aerial360VirtualTourGallery, PhotoGallery, VideoGallery};