import '../App.css';
import React, {useLayoutEffect} from 'react';
import './about.css';
import {Container , Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import HomeContactSection from '../HomeContactSection';
import Logo from '../images/webp/dsi-logo.webp';
import BlueDotsGrid from '../images/webp/blue-dots-grid.webp';
import DroEduIcon from '../images/webp/drone-edu-icon.webp';
import TechInnovIcon from '../images/webp/tech-innov-icon.webp';
import InspireGif from '../images/webp/inspire1.webp'
import LightDarkHeading from '../LightDarkHeading/LightDarkHeading';
import CofoundersImg from '../images/webp/cofounders.webp';
import DharnaImg from '../images/webp/dharna.webp';
import DhruvinImg from '../images/webp/dhruvin.webp'; 
import KinshukImg from '../images/webp/kinshuk.webp';

function Verticals(props){
    return(
        <>
        <Col xs={12} lg={4} className='verticals-component'>
            <img className='verticals-icon fit-image' src={props.icon} alt= {props.alt}/> 
            <h1 className='verticals-header'> <HeaderUnderlineAnimation text={props.title}  lineColor="blue"> </HeaderUnderlineAnimation></h1>
            <p> {props.description} </p>
        </Col>
        </>
    )
}


function About() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <>
            <div className='about-component'>
                <header className="about-inner-div ">                
                    <div className="about-header">
                    <h1> The Story of our Passion<br/> Brought To Your Service </h1>
                    </div>
        
                </header>
            
            
            <Container className='about-conatiner'>
                <div className='about-dsi-div'>
                    <Row>
                        <Col xs={12} lg={6} className='about-dsi-logo-col'>
                            <img className='about-dsi-logo' src={Logo} alt='DSI-Logo' />
                        </Col>
                        <Col xs={12} lg={6}>
                            <div className='about-heading-overlay'>
                                <h2 className='about-heading'> About DSI </h2>
                                <img className='about-overlay-img' src={BlueDotsGrid} alt='Blue Dots'  />
                            </div>
                        
                            <div>
                            <p className='text-justify'>
                                Drone School India was born out of the passion of two zealous drone pilots wanting to share their knowledge and collective expertise with the rest of the world. Incepted in 2019, DSI aims to provide:
                            <br /><br />
                                1. Efficacious and detailed solutions to industries; be it from wider applications such as aerial surveying and cinematography to deeper applications resulting from extensive research, testing and development. 
                                <br /><br />
                                2. Evolving real world education and training about drones, to help create a community of passionate drone flyers and enthusiasts and give back to the society by providing innovation.
                                <br /><br />
                                At Drone School India, we step into the shoes of our customers. We airlift them from the problem areas with empathy, discover the way forward with critical thinking and analysis, and safely deliver their goals with the best possible solutions.                            
                            </p>
                            </div>

                            <div>

                            </div>
                        </Col>
                    </Row>
                    </div>

                    {/* <div className='about-qoute'>
                        <h2> Add a Quote </h2>
                        <h3> Add a Subheading</h3>
                    </div> */}

                    <div className='what-we-do-div'>

                    <LightDarkHeading lightHeading='What We Do' darkHeading='Our 3 Vericals of Operations ' />

                    <Row className='verticals-row'>
                        <Verticals icon={DroEduIcon} alt='Drone Training Icon' title='Education and Training' description='We provide educational courses in aerial robotics (multirotor UAVs) and training in aerial cinematography, drone coding and introductory courses for the young enthusiasts.'/>
                        <Verticals icon={InspireGif} alt='Inspire1 Gif' title='Aerial Photo and Cinematics' description='We provide high resolution films and images for multitudes of projects, from aerial surveying to drone filming, 360 degree virtual tours and much more.'/>
                        <Verticals icon={TechInnovIcon} alt='Technology & Innovation Icon' title='Technology and Innovation' description = ' We provide sound, effective and customised aerial solutions to your projects, resulting from deep research, understanding and testing.'/>
                    </Row>
                    </div>


                    <LightDarkHeading lightHeading='Meet Our Co-Founders' darkHeading='Driving the Company with their Vision.' />
                    <div>
                        <img src={CofoundersImg} className='fit-image' alt='cofounders-img'/>
                        <br /><br /><br />
                        {/* <p className='text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five.Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five </p> */}
                        </div>
                    <div>
                        <Row className='cofounders-row'>
                            <Col xs={12} lg={6}>
                                <img src={DharnaImg} className='fit-image cofounders-img' alt='Dharna'/>
                            </Col>
                            <Col xs={12} lg={6}>
                            <div className='text-left'>
                                <h2> Dharna Nar</h2>
                                <h4> CTO, Drone-Acharya</h4>
                            </div>

                            <p className='cofounder-quote'> "Dream it, Create it. Manifestation begins with Imagination."</p>

                            <div className='text-justify about-cofounder'>
                            An engineer at heart and an entrepreneur by choice, Dharna has an affinity for drones and can often be found tinkering with them. She believes that a combination of empathy and technology is the key to finding solutions. Coding and building smart robots is her pastime. A calm mind brimming with positivity, she is a foodie who loves to travel.                            </div>
                            </Col>
                        </Row>


                        <Row className='cofounders-row'>
                            <Col xs={12} lg={6}>
                                <img src={DhruvinImg} className='fit-image cofounders-img' alt='Dhruvin'/>
                            </Col>
                            <Col xs={12} lg={6}>
                            <div className='text-left'>
                                <h2> Dhruvin Dodhiya</h2>
                                <h4> CEO, Drone-Acharya</h4>
                            </div>

                            <p className='cofounder-quote'> "Empathy driven Technology is the keystone of sustainable solutions."</p>

                            <div className='text-justify about-cofounder'>
                            A Young Serial Entrepreneur and an experienced photographer, Dhruvin is a passionate drone pilot. He loves to teach photography and aerial filming. For him, work is fun when one follows one's passion. Adrenaline hits him as he witnesses and films moments from a bird's eye view. With a blend of creativity and an entrepreneurial mind, identifying problems and ideating solutions comes naturally to him. He loves traveling solo.                            </div>
                            </Col>

                        </Row>

                    
                    </div>
                
                </Container>
                <HomeContactSection />

            </div>
        </>
    )}

export default About;