import '../App.css';
import React, {useLayoutEffect} from 'react';
import './courses.css';
import {Container , Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import LightDarkHeading from '../LightDarkHeading/LightDarkHeading';
import {PhotoGallery, VideoGallery} from '../Gallery/AerialGallery';
import droneGif from '../images/webp/workshop-drone.webp';

import gallimg1 from '../images/webp/workshop-img1.webp';
import gallimg2 from '../images/webp/workshop-img2.webp';
import gallimg5 from '../images/webp/workshop-img5.webp';
import gallimg3 from '../images/webp/workshop-img3.webp';
import gallimg6 from '../images/webp/workshop-img6.webp';
import gallimg7 from '../images/webp/workshop-img7.webp';
import videothumbnail from '../images/webp/workshop-video thumbnail.webp';
import droneBuildingImg from '../images/webp/building-drone.webp';

import workshopvideo1 from '../videos/workshopvideo1.mp4';
import DroneVector from '../images/webp/droneVector.gif';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import {NavLink} from 'react-router-dom';

// import { useEffect, useState } from "react";

function HighlightsAnimation(props) {
    return(
        <>
            {/* <Col xs={12} lg={3}> */}
            <div className='highlights-component'>
                <span className='highlights-num'>{props.num}</span>
                <p className='highlights-text'>{props.text}</p>
            </div>
            {/* </Col> */}
        </>
        )
    }


function CourseOverviewSection(){
    return(
        <>
        <Row>
        <Col xs={12} lg={6}>
        <img src={droneGif} alt='drone-vector-gif' className='fit-image'/>
        </Col>
        <Col xs={12} lg={6}>
        <div className='course-overview-div'>
            <h2 className='heading'><HeaderUnderlineAnimation text='COURSE OVERVIEW' lineColor='orange'></HeaderUnderlineAnimation></h2>
            <p className= 'text-justify'>Get into the world of Drones! This course for the young minds and getting them into the world of drones. Our team of passionate instructors will train you on everything you need to know about drones from understanding all the parts, sensors and flying techniques to all the rules and regulations. By the end of the course, you will be able to develop, modify, code and fly a drone in the open sky and begin your journey of exploring aerial robotics! You will learn the foundational base concepts theoretically and implement them practically. This would enhance the learning experience of the student. Explore our syllabus to know more!</p>
        </div>
        </Col>
        </Row>
        </>
    )
}


function Syllabus(){
    return(
        <>
        <div className='syllabus-div'>
            <Row>
                <Col xs={12} lg={6}>
                <div className='syllabus-header'>
                    <LightDarkHeading lightHeading='Syllabus' darkHeading='What You Will Learn From this Course.'/>
                </div>
                </Col>
                <Col xs={12} lg={6}>
                <img src={DroneVector} alt='droneVector' className='syllabus-section-drone'/>
                <div className='syllabus-modules'>
                <h2><HeaderUnderlineAnimation text='Level 1' lineColor='Orange' /></h2>
                <br />
                    <ul>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to UAVs' lineColor='blue' /></h2>
                            <p>Basic drone terminologies, types of drones, applications of drones, categories of drones.</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Rules and Regulations' lineColor='blue' /></h2>
                            <p>Drone flying zones, latest rules and regulations, altitude restrictions, UIN, digital sky platform, do’s and don'ts.</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Fundamental Principles of Multirotor UAVs' lineColor='blue' /></h2>
                            <p>Drone Maneuvering and Controls terminologies, motor configuration, DOF, basic dynamics</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='General Block Diagram' lineColor='blue' /></h2>
                            <p>Understanding FC, ESC, battery, GPS, IMU, telemetry, sensors, propellers, motors and their connections with each other. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Assembling Nano Drone' lineColor='blue' /></h2>
                            <p>Introduction to Pluto drone-kit, build your nano drone, sensor calibration, pre-flight checks.</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Drone Flying with Instructor' lineColor='blue' /></h2>
                            <p>Instructions for drone flying, introduction to drone flying app, hands-on drone flying in different flying modes.</p>
                        </li>
                        <h2><HeaderUnderlineAnimation text='Level 2' lineColor='Orange' /></h2>
                    <br />
                        <li>
                            <h2><HeaderUnderlineAnimation text='Coding And Flying' lineColor='blue' /></h2>
                            <p>Understanding block programming, developing logic, coding and performing experiments on drone.
                            </p>
                        </li>
                        
                    </ul>
                </div>
                </Col>
            </Row>
            </div>
        
        </>
    )

}

function HeroSection() {
    return(
        <>
            <header className="hero-header aerial-robotics-header">
        
                <img src={droneBuildingImg} alt='workshop-header-img' className='drone-build-image'/>
                
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'> Aerial Robotics - JUNIOR</h1>
                <h4>Experiential Learning For Aspiring Drone Pilots </h4>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Enroll Now' color='black'/></NavLink>
                </div>
    
            </header>
        </>
    )
}

function Prerequisities(){
    return(
        <>
        <div className='pre-requisities-div'>
            <Row>
                <Col xs={12} lg={6}>
                    <div className='p-4'>
                        <h2 className='text-left heading'><HeaderUnderlineAnimation text='Pre-Requisites' /></h2>
                        <p className='text-left'>For a better understanding of the course, we encourage you to have a basic understaning of these topics. </p>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className='pre-requisities-content border'>
                    <Row>
                    <Col>
                        <ul className='text-left pre-requisites-list'>
                            <li>- Understanding Math Equations</li>
                            <li>- Coordinate Systems </li>
                            <li>- Sensors</li>
                            
                        </ul>
                    </Col>
                    <Col>
                        <ul className='text-left pre-requisites-list'>
                            <li>- Electronic Components</li>
                            <li>- Basic Laws of Physics</li>
                            {/* <li>- Lorem Ipsum</li> */}
                        </ul>
                    </Col>
                    </Row>

                    </div>
                </Col>
            </Row>
        </div>
        </>
    )
}

function CoursesPhotoGallery(){
    let photos = [gallimg1,gallimg2,gallimg3,gallimg5,gallimg7, gallimg6];
    return(
        <>
        <div className='courses-gallery-div'>
            <h1 className='heading'><HeaderUnderlineAnimation text='Photo Gallery'/></h1>
            <PhotoGallery photoArray = {photos} />
        </div>
        </>
    )
}

function CoursesVideoGallery(){

    let videos =  [
        {   id :1,
            src: workshopvideo1,
            poster: videothumbnail,
            lg : 6
        },
    ]

    return(
        <>
        {/* <div className='courses-gallery-div'>
            <h1 className='heading'><HeaderUnderlineAnimation text='Video'/></h1>
            
                <VideoGallery videoArray = {videos} />
            
        </div> */}
        </>
    )
}

function AerialRoboticsWorkshop() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className='courses-component'>
            <HeroSection />
                <Container>
                    <div className='highlights-div'>
                    <Row>
                    <Col xs={12} lg={3}>
                        <HighlightsAnimation num='01' text="Learn"/>
                    </Col>
                    <Col xs={12} lg={3}>
                        <HighlightsAnimation num='02' text="Build"/>
                    </Col>
                    <Col xs={12} lg={3}>
                        <HighlightsAnimation num='03' text="Code"/>
                    </Col>
                    <Col xs={12} lg={3}>
                    <HighlightsAnimation num='04' text="Fly"/>
                    </Col>
                    </Row>
                    </div>

                    <CourseOverviewSection />
                    <Prerequisities />
                    <Syllabus />
                    <CoursesPhotoGallery />
                    <CoursesVideoGallery />
                    <HomeContactSection />
                
                </Container>
            </div>
        </>
    )}

export default AerialRoboticsWorkshop;
export {HighlightsAnimation};