import './App.css';
import React from 'react';

function Error() {
  return (
    <>
    <div className="App">
      <header className="App-header">
        <h1> Oops! Page Not Found.</h1>
      </header>
    </div>
    </>
  );
}

export default Error;
