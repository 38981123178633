import React from 'react';
import './App.css';
import './home.css';
import './clientCarousel.css'
import {Container, Col, Row} from 'react-bootstrap';
import GIACL from './images/clientelle_images/GIACL-logo.webp';
import zee5 from './images/clientelle_images/zee5-logo.webp';
import sr from './images/clientelle_images/SR-logo.webp';
import saidham from './images/clientelle_images/saidhamlogo.webp';
import riidl from './images/clientelle_images/riidl-logo.webp';
import NHAI from './images/clientelle_images/NHAI-logo.webp';
import dronaAviation from './images/clientelle_images/drona-aviation-logo.webp';
import tss from './images/clientelle_images/tss-logo.webp';
import somaiya from './images/clientelle_images/somaiya-logo.webp';
import DFI from './images/clientelle_images/DFI-logo.webp';
import NTC from './images/clientelle_images/NTC-Logo.webp';
import SBTL from './images/clientelle_images/saj-logo.webp';
import BV from './images/clientelle_images/bella-vista-logo.webp';
import Konart from './images/clientelle_images/konart-steel-logo.webp';

function ClientsMarquee(props) {
  return (
    <>
      <div className="Marquee">
        <div className={"Marquee-content" + props.dir}>
        {
        props.logo.map((img,index) => (
          <div className="Marquee-tag"><img src={img} alt='client-logo'/>1</div>
          ))}
        </div>
      </div>   
    </>
  )
}


function Clients() {

let ClientLogos = [riidl,somaiya, DFI, dronaAviation,tss, zee5,SBTL,BV, Konart,GIACL,NHAI,sr,saidham,NTC,riidl,somaiya, DFI, dronaAviation];

  return (
    <>
    <Container>
    <div className='homeContactDiv'>
    <Row>
    <Col className='homeContactText mr-auto' xs={12} lg={12} >
      <h2 className='lightHeading mt-4'>Our Associates </h2>
      <h2 className='darkHeading'> Trusted & Loved By </h2><br />

    </Col>
    </Row>
    </div>
    </Container>

    <ClientsMarquee logo = {ClientLogos} dir=' '/>
    </>
  );
}

export default Clients;
export {ClientsMarquee};
