import '../App.css';
import React,{useLayoutEffect} from 'react';
import './courses.css';
import AerialFilmingCourseBrochure from '../images/AerialFilmingCourseBrochure.pdf';
import {Container , Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import LightDarkHeading from '../LightDarkHeading/LightDarkHeading';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import {NavLink} from 'react-router-dom';
import {PhotoGallery, VideoGallery} from '../Gallery/AerialGallery';
import galleryImg1 from '../images/webp/af-img1.webp';
import galleryImg2 from '../images/webp/af-img2.webp'; 
import galleryImg3 from '../images/webp/af-img3.webp'; 
import galleryImg4 from '../images/webp/af-img4.webp'; 
import DroneVector from '../images/webp/droneVector.gif';
import filmingGif from '../images/drone_photo___video.gif'

function Highlights(props) {
    return(
        <>
            <Col xs={12} lg={4}>
            <div className='highlights-component'>
                <span className='highlights-num'>{props.num}</span>
                <h2 className='highlights-text'> {props.text}</h2>
            </div>
            </Col>
        </>
        )
    }

function CourseOverviewSection(){
    return(
        <>
        <div className='course-overview-div'>
        <h2 className='heading'><HeaderUnderlineAnimation text='COURSE OVERVIEW' lineColor='orange'></HeaderUnderlineAnimation></h2>
        <p className='text-justify'>Have you ever seen yourself on the sets of several television series, documentaries and films with your drone? Have you envisioned yourself shooting the perfect Fly Over shot across the Grand Canyon or a nail biting Tracking shot of a race car across a historic race track?
</p> 
<p className='text-justify'>
Then this course is for you! Our team of expert instructors have curated this course taking only quality film making as the Number 1 priority. You will be trained in an all round aspect: from drone and camera technical data, camera shooting knowledge and clean aesthetics tricks. They will ensure that once you complete this course, you will be able to develop the Aerial Films of your dreams from scratch worthy of screening, festivals, magazines and tabloids. Explore our syllabus to know more!
</p>
            
            </div>
        </>
    )
}


function Syllabus(){
    return(
        <>
        <div className='syllabus-div'>
            <Row>
                <Col xs={12} lg={6}>
                <div className='syllabus-header'>
                    <LightDarkHeading lightHeading='Syllabus' darkHeading='What You Will Learn From this Course.'/>
                </div>
                </Col>
                <Col xs={12} lg={6}>
                <img src={DroneVector} alt='droneVector' className='syllabus-section-drone'/>
                <div className='syllabus-modules'>
                    <ul>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to UAVs' lineColor='blue' /></h2>
                            <p>Basic drone terminologies, types of drones, applications of drones, categories of drones. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Rules and Regulations' lineColor='blue' /></h2>
                            <p>Drone flying zones, latest rules and regulations, altitude restrictions, UIN, digital sky platform, do’s and don'ts. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Fundamentals of Multirotor UAV' lineColor='blue' /></h2>
                            <p> Drone maneuvering and control terminologies, motor configuration, sensors, general block diagram of multirotor drones.</p>
                        </li>
                        <li>
                            <h4><HeaderUnderlineAnimation text='Assembling and Disassembling' lineColor='blue' /></h4>
                            <p> Unpacking and packing drone kit, mounting,  un-mounting battery, CW, CCW propellers on respective motors, camera, etc.   </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Remote Controller and GCS' lineColor='blue' /></h2>
                            <p>Brief introduction to drone RC, joysticks, various ports, antenna, mobile application and its features.  </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Important Drone Checklist' lineColor='blue' /></h2>
                            <p> Pre-flight, in-flight, post-flight checklist</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Emergency Management' lineColor='blue' /></h2>
                            <p>Emergency identification and handling, probable causes of failure, failsafe methods, post emergency flight checks. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Drone Flying with Instructor' lineColor='blue' /></h2>
                            <p>Hands-on drone flying. Practice single and dual drone controls</p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to Camera' lineColor='blue' /></h2>
                            <p>Drone camera specifications, features, ISO, shutter speed, aperture, format, auto settings, manual settings, video resolution, frame rate, gimbal control, ND filters. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Introduction to Aerial Filming' lineColor='blue' /></h2>
                            <p>Case studies, significance, and techniques of filming various aerial shots focusing on single and dual controls. Subject/location based planning of aerial shots. </p>
                        </li>
                        <li>
                            <h2><HeaderUnderlineAnimation text='Aerial Filming with Instructor' lineColor='blue' /></h2>
                            <p> Practical hands-on aerial filming of shots studied in previous module. </p>
                        </li>
                        
                    </ul>
                </div>
                </Col>
            </Row>
            </div>
        
        


        {/* <div class="foo">
        <Row>
        <Col lg={3}>
            <div class="bar">Header Bar</div>
        </Col>
        <Col lg={9}>
            <div class="scrollable_content">
                <div class="someOther">Line 1</div>
                <div class="someOther">Line 2</div>
                <div class="someOther">Line 3</div>
                <div class="someOther">Line 4</div>
                <div class="someOther">Line 5</div>
                <div class="someOther">Line 6</div>
                <div class="someOther">Line 7</div>
                <div class="someOther">Line 8</div>
            </div>
        </Col>
        </Row>
        </div> */}
        </>
    )
}

// function Prerequisities(){
//     return(
//         <>
//         <div className='pre-requisities-div'>
//             <Row>
//                 <Col xs={12} lg={6}>
//                     <div className='p-4'>
//                         <p className='text-left heading'><HeaderUnderlineAnimation text='Pre-Requisites' /></p>
//                         <p className='text-left'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//                         Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
//                     </div>
//                 </Col>
//                 <Col xs={12} lg={6}>
//                     <div className='pre-requisities-content'>
//                     <Row>
//                     <Col>
//                         <ul>
//                             <li>- Lorem Ipsum</li>
//                             <li>- Lorem Ipsum</li>
//                             <li>- Lorem Ipsum</li>
//                         </ul>
//                     </Col>
//                     <Col>
//                         <ul>
//                             <li>- Lorem Ipsum</li>
//                             <li>- Lorem Ipsum</li>
//                             <li>- Lorem Ipsum</li>
//                         </ul>
//                     </Col>
//                     </Row>

//                     </div>
//                 </Col>
//             </Row>
//         </div>
//         </>
//     )
// }


function CoursesPhotoGallery(){
    let photos = [galleryImg1, galleryImg2, galleryImg3, galleryImg4];
    return(
        <>
        <div className='courses-gallery-div'>
            <p className='heading'><HeaderUnderlineAnimation text='Photo Gallery'/></p>
            <PhotoGallery photoArray = {photos} />
        </div>
        </>
    )
}

// function CoursesVideoGallery(){

//     let videos =  [
//         {   id :1,
//             // src: video1,
//             poster: img1
//         },
//         {   id :2,
//             // src : video1,
//             poster: img2
//         },
//     ]


//     // let videos = [video1, video1];
//     return(
//         <>
//         <div className='courses-gallery-div'>
//             <p className='heading'><HeaderUnderlineAnimation text='Video Gallery'/></p>
//             <VideoGallery videoArray = {videos} />
//         </div>
//         </>
//     )
// }


function HeroSection() {
    return(
        <>
            {/* <header className="hero-header">
                <img src={img2} alt='vt-header-img' className='fit-image hero-img'/>
                <div className="overlay">
                <h1 className='text-uppercase'> Aerial Filming  Course</h1>
                <h4>Lorem Ipsum is a dummy text </h4>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Enroll Now' color='white'/></NavLink>
                </div>
            </header> */}
                <header className="hero-header filming-header">
                <img src={filmingGif} alt='filming-header-gif' className='filming-vector-image fit-image'/>
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'> Aerial Filming  Course </h1>
                <h4>Envision & Capture </h4>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Enroll Now' color='black'/></NavLink>
                {/* <br/> */}
                <a href={AerialFilmingCourseBrochure} download><ButtonAnimation text='Brochure' color='black'/></a>
                </div>
            </header>
        </>
    )
}

function AerialFilmingCourse() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className='courses-component'>
            <HeroSection />
                <Container>
                    <div className='highlights-div'>
                    <Row>
                        <Highlights num='01' text="Learn"/>
                        <Highlights num='02' text="Fly"/>
                        <Highlights num='03' text="Film"/>
                        {/* <Highlights num='04' text="Fly"/> */}
                    </Row>
                    </div>

                    <CourseOverviewSection />
                    {/* <Prerequisities /> */}
                    <Syllabus />
                    <CoursesPhotoGallery />
                    {/* <CoursesVideoGallery /> */}
                    <HomeContactSection />
                
                </Container>
            </div>
        </>
    )}

export default AerialFilmingCourse;