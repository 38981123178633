import './App.css';
import Footer from './Footer';
import NavbarMenu from './Navbar';
import React,{useEffect} from 'react';
// import ReactDom from 'react-dom';
import {Route,Switch} from 'react-router-dom';
import Contact from './Contact';
import AerialPhotoGallery from './Gallery/AerialGallery';
import {AerialVideoGallery,Aerial360VirtualTourGallery} from './Gallery/AerialGallery';
import About from './About/About';
import Books from './Books/Books';
import Home from './Home';
import Error from './Error';
import DroneBootcamp from './Courses/DroneBootcamp';
import AerialRoboticsWorkshop from './Courses/AerialRoboticsWorkshop';
import AerialFilmingCourse from './Courses/AerialFilmingCourse';
import AerialCinematography from './Services/AerialCinematography'
import VirtualTours from './Services/VirtualTours';
import Surveying from './Services/Surveying';
import RoadSurvey from './Services/RoadSurvey';
import ReactGa from 'react-ga';

function App() {
  useEffect(() => {
    ReactGa.initialize('UA-199880282-1')

    //to report pageview
    ReactGa.pageview(window.location.pathname + window.location.search)
  },[])


  return (
    <>
    <NavbarMenu />
    <div className="App">
      <header className="App-headers">
        <Switch>
          {/* <Route exact path='/' component={Home} /> */}
          <Route exact path='/' component={() => <Home />} />
          <Route path='/contact' component={Contact} />
          <Route path='/aerial-photo-gallery' component={AerialPhotoGallery} />
          <Route path='/aerial-video-gallery' component={AerialVideoGallery} />
          <Route path='/aerial-360-virtual-tour-gallery' component={Aerial360VirtualTourGallery} />
          <Route path='/aerial-robotics-workshop' component={AerialRoboticsWorkshop} />
          <Route path='/drone-coding-bootcamp' component={DroneBootcamp} />
          <Route path='/aerial-filming-course' component={AerialFilmingCourse} />
          <Route path='/aerial-cinematography' component={AerialCinematography} />
          <Route path='/virtual-tours' component={VirtualTours} />
          <Route path='/surveying' component={Surveying} />
          <Route path='/drone-geotagged-video-road-survey' component={RoadSurvey} />
          <Route path='/about' component={About} />
          <Route path='/books' component={Books} />
          
          <Route component={Error} />
        </Switch>
      </header>
    </div>
    <Footer />
    </>
  );
}

export default App;
