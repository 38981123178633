import '../App.css';
import React,{useLayoutEffect} from 'react';
import './services.css';
import {Container , Row, Col,Card} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import { HighlightsAnimation } from '../Courses/AerialRoboticsWorkshop';
import surveyVector from '../images/webp/survey_vector.webp'; 
import { motion } from "framer-motion";
import droneVector  from '../images/webp/droneVector.gif';
import initialSurvey from '../images/webp/land-survey.webp';
import progessMonitoring from '../images/webp/Progress-monitoring.webp'; 
import Inspection from '../images/webp/hazardAssessment.webp';
import Contour from '../images/webp/contour.webp';
import PointCloud from '../images/webp/3d-pointcloud.webp';
import TexturedMesh from '../images/webp/3d-textured-mesh.webp';
import DSM from '../images/webp/dsm.webp';
import DTM from '../images/webp/dtm.webp';
import Orthophoto from '../images/webp/orthophoto.webp';



import headerVector from '../images/webp/survey.webp';
import '../Courses/courses.css';

let UseCasesArray = [
    {   
        id : '1',
        src : initialSurvey,
        title : 'Initial Survey',
        info : 'Initial surveys of huge projects such as airport sites, railway lines, etc. can be taken with ease and efficiency. Huge segments of land can be surveyed in less amount of time using drones with every feature being marked and highlighted.'
    },
    {
        id : '2',
        src : progessMonitoring,
        title : 'Progress Monitoring',
        info : 'Development and construction projects can be assessed timely and progress can be monitored assessing every step, without hindering the ongoing work and every minor detail can be captured and studied.'
    },
    {
        id : '3',
        src : Inspection,
        title : 'Hazard Assessments',
        info : 'Safety is ensured in places where there is a risk of health or life while assessing a certain land feature. Using various aerial camera angles and altitudes, a detailed survey of hazards can be taken, from which cause and spread of hazard can be determined.'
    }

]

let DeliverablesArray = [
    {   
        id : '1',
        src : Orthophoto,
        title : 'Orthomosaic Maps',
        info : 'Orthophoto is a high resolution aerial image stitched together by a specialized software and corrected for perspective, camera tilt, lens distortion and terrain relief, creating a georeferenced image map that can be used for accurate mapping and measurement. This technology is often used in industries such as agriculture, construction, and land surveying.'
    },
    {
        id : '2',
        src : DSM,
        title : 'DSM',
        info : 'Drone DSM (Digital Surface Model) is a high-resolution 3D model of the Earths surface that captures all the features and objects present on the ground, including trees, buildings, and other structures. It provides valuable information for applications such as urban planning, land use management, and environmental monitoring.'
    },
    {
        id : '3',
        src : DTM,
        title : 'DTM',
        info : 'Drone DTM (Digital Terrain Model), is a high-resolution 3D model that represents the bare ground surface without any objects or vegetation present on the terrain. It provides information about the topography of an area and is useful for applications such as land surveying, flood modelling, soil erosion analysis, and infrastructure planning.'
    },
    {
        id : '4',
        src : Contour,
        title : 'Countour Maps',
        info : 'Contours are lines that connect points of equal elevation on a DTM or DSM. They provide valuable and high accuracy information about the topography of an area, including the steepness of slopes and the shape of the terrain. Contour maps created from drone data are useful for a variety of applications, including land use planning, construction, and natural resource management.'
    },
    {
        id : '5',
        src : PointCloud,
        title : '3D Point Cloud',
        info : '3D Point Cloud is a collection of millions of georeferenced data points in 3D space captured by drones camera system. Each point represents a specific location in the surveyed area and has a corresponding elevation value. The points together denote the surfaces of objects and terrain features within an area, which allows surveyors to create extremely accurate 3D maps and models of those areas.'
    },
    {
        id : '6',
        src : TexturedMesh,
        title : '3D Textured Mesh',
        info : 'This is a 3D model created from drone data that includes both geometry and texture information. It is derived from the point cloud by creating a mesh of millions of interconnected faces and painting each one with the color and texture from the original photos. This is useful for applications such as visualizing architecture, engineering, and construction projects, as well as creating virtual reality experiences.'
    }

]


function HeroSection() {
    return(
        <>
            <header className="hero-header survey-header">
                <img src={headerVector} alt='survey-header-img' className='survey-vector-image fit-image'/>
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'> Aerial Surveying & Mapping</h1>
                <h4>Precision Land Mapping from Above</h4>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='Get in Touch' color='black'/></NavLink>
                </div>
            </header>
        </>
    )
}

function OverviewSection(){
    return(
        <>
            <Row className='overview-row'>
                    <Col xs={12} lg={6} >
                    {/* <div>
                    <img src={surveyVector} className='fit-image' alt='surverying-img' />
                    </div> */}
                    <div className="d-flex">
                        <img src={surveyVector} alt="survey-vector" className="contact-img"></img>
                        <motion.div className="drone-img"
                        animate={{
                        x: ["0vw","-10vw","-30vw","-20vw","-5vw"], 
                        y: ["-5vh","-12vh","3vh","20vh", "0vh"],  
                            }}
                        transition={{
                        duration: 10,
                        ease: "easeInOut",
                        times: [0, 0.3,0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1
                        }}>

                        <img src={droneVector} alt="drone-vector" className="drone-img"></img><br />
                        </motion.div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <p className='content p-3'> Land mapping and surveying with the use of drones, equipped with high-resolution sensors and cameras are used to collect accurate and geotagged data of land. Specialized photogrammtery softwares processes this data into standardized GIS outputs. Terrains inaccessible by man can be easily surveyed without any risks from different heights and various camera angles to give you the perfect lay of the land to plot your next infrastructural project. Timely assessments can be efficiently taken with ease, without hindrance to any work giving you the clarity of your next step.
                        This technology provides accurate and detailed information that can be used for a wide range of applications, including land surveying, urban planning, infrastructure development, inspection and natural resource management. With the ability to cover large areas quickly and safely, drone land mapping and surveying has become an increasingly popular choice for professionals in various industries seeking efficient and precise mapping solutions.</p>
                    </Col>
                </Row>
        </>
    )
}


function KeyBenefits(){
    return(
        <>
            <p className='heading industry-head'><HeaderUnderlineAnimation text='Key Benefits'/> </p>
                    <Row>
                        <Col xs={12} lg={3} className='survey-key-benefits'> 
                        <div className='highlights-div'>
                            <HighlightsAnimation num='01' text="Accuracy"/>
                            <p> Drones equipped with advanced sensors and cameras can capture highly accurate data, providing precise measurements and mapping for various land surveys.</p>
                        </div>
                        </Col>
                        <Col xs={12} lg={3} className='survey-key-benefits'>  
                            <HighlightsAnimation num='02' text="Efficiency"/>
                            <p> Land mapping with drones is a much faster and more efficient way to survey land compared to traditional methods, covering large areas in a short amount of time.</p>
                        </Col>   
                        <Col xs={12} lg={3} className='survey-key-benefits'>  
                            <HighlightsAnimation num='03' text="Safety"/>
                            <p>Using drones for land mapping eliminates the need for workers to climb tall structures or work in dangerous areas, reducing the risk of accidents and injuries.</p>
                        </Col>   
                        <Col xs={12} lg={3} className='survey-key-benefits'>  
                            <HighlightsAnimation num='04' text="Economical"/>
                            <p>Land mapping using drones is typically more cost-effective than traditional land surveying methods. It reduces the need for expensive equipment, labour, and travel costs.</p>
                        </Col>   
                    </Row>
        </>
    )
}

function UseCasesSection(props){
    let industriesArray = props.industries;
    return(
        <>
        <div className='industries-section'>
        <p className='heading industry-head'><HeaderUnderlineAnimation text='Use Cases'/> </p>
        <Row>
        {
            industriesArray.map((industryCard, index) => (
            <>
            <Col xs={12} lg={4}>
                <Card className='industry-card'>
                    <Card.Img src={industryCard.src} alt="industry" className='industry-img' />
                    <div class="industry-info">
                        <p>{industryCard.info}</p>
                    </div>
                    <Card.Body className='industry-card-body'>        
                        <div>
                        <h1><Card.Title className="text-uppercase mb-0"><HeaderUnderlineAnimation text={industryCard.title} lineColor='blue' /></Card.Title></h1>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            </>
            ))}
        </Row>
        </div>
        </>
    )
}

function DeliverablesSection(props){
    let industriesArray = props.industries;
    return(
        <>
        <div className='industries-section'>
        <p className='heading industry-head'><HeaderUnderlineAnimation text='Deliverables'/> </p>
        <Row>
        {
            industriesArray.map((industryCard, index) => (
            <>
            <Col xs={12} lg={4}>
                <Card className='industry-card'>
                    <Card.Img src={industryCard.src} alt="industry" className='industry-img' />
                    
                    <Card.Body className='industry-card-body'>        
                        <div>
                        <h1><Card.Title className="text-uppercase mb-0"><HeaderUnderlineAnimation text={industryCard.title} lineColor='blue' /></Card.Title></h1>
                        <p style={{textAlign: 'justify'}} >{industryCard.info}</p>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            </>
            ))}
        </Row>
        </div>
        </>
    )
}

function Surveying() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className='about-component'>
                <HeroSection />
                <Container>
                    <OverviewSection />
                    <KeyBenefits />
                    {/* <UseCasesSection industries={UseCasesArray} /> */}
                    <DeliverablesSection industries={DeliverablesArray} />
                    <HomeContactSection />
                </Container>
            </div>
        </>
    )}

export default Surveying;


