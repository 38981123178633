import '../App.css';
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './lightdarkheading.css';

function LightDarkHeading(props) {
    return (
        <>
            <Row>
                <Col className='homeContactText mr-auto' xs={12} lg={12} >
                    <h2 className='lightHeading'>{props.lightHeading}</h2>
                    <h2 className='darkHeading'> {props.darkHeading} </h2><br /> 
                </Col>
            </Row>
        </>
        )
    }

export default LightDarkHeading;