import './App.css';
import React, {useLayoutEffect} from 'react';
import {NavLink} from 'react-router-dom';
import HomeAboutSection from './HomeAboutSection';
import HomeContactSection from './HomeContactSection';
import Clients from './Clients';
import Services from './Services';
import Testimonials from './Testimonials';
import Typical from 'react-typical';
import HeaderVideo from './videos/header-video.mp4';
import './home.css';
// import { Button } from 'react-bootstrap';
// import HeaderUnderlineAnimation from './HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import ButtonAnimation from './ButtonAnimation/ButtonAnimation';


function Home() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <>
    <div className="App">
      <header className="App-header">
        <video autoPlay muted loop src={HeaderVideo} type="video/mp4"/>
        <div className="overlay">
        <h2> WELCOME TO DSI </h2>
        <span> A Complete Hub For </span>
        <Typical 
          loop ={Infinity}
          wrapper="h1"
          steps={["Drone Training",1000,"Drone-as-a-Service",1000,"Aerial Cinematography",1000,"Drone Photography",1000,"360 Virtual Tours",1000,"Drone Survey",1000,"Land Mapping",1000,"Geotagged Road Survey",1000, "Infra Progress Monitoring",1000]}
        />
      
        {/* <Button className='myBtn'> SHOWREELS</Button> */}
        <NavLink  to="/aerial-video-gallery" className='text-decoration-none'><ButtonAnimation text='showreels' color='white'/></NavLink>
        </div>
      </header>
      <HomeAboutSection />
      <Services />
      <Clients />
      <Testimonials />
      <HomeContactSection />
      <br />
      <br />
    </div>
    </>
  );
}

export default Home;
