import './App.css';
import React,{useLayoutEffect} from 'react';
import './contact.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Container, Col, Row} from 'react-bootstrap';
import ContactImg  from './images/webp/contactImg.webp';
import droneVector  from './images/webp/droneVector.gif';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import Email from '@material-ui/icons/Email';
import { motion } from "framer-motion";
import ContactForm from './ContactForm'; 
import droneContactVideo from './videos/droneContactVideo.mp4';

function Contact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});


  return (
    <>
    <div className="contact-main-div">
      <header className="contact-inner-div">
        <div className='contact-header'>
        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
          <source src={droneContactVideo} type="video/mp4" width="100%" height="250px"  />
        </video>
          <h1> CONTACT US </h1>
        </div>


        <Container fluid className="contact-conatiner">
          <Row>
            <Col xs={12} lg={6}>
            <div className="d-flex">
            <img src={ContactImg} alt="contact-img" className="contact-img"></img>
            <motion.div className="drone-img"
              animate={{
              x: ["-10vw","-10vw","-30vw","-10vw"], 
              y: ["3vh","-5vh","3vh", "-3vh"],  
                  }}
            transition={{
              duration: 5,
              ease: "easeInOut",
              times: [0, 0.3, 0.5, 1],
              loop: Infinity,
              repeatDelay: 1
            }}>

            <img src={droneVector} alt="drone-vector" className="drone-img"></img><br />
            </motion.div>
            </div>
            <ul className="contact-list">
                  <a className='mt-4 d-flex' style={{padding:0, textDecoration : 'none', color: 'inherit'}} href='tel:+91 9967490255'>
                    <PermPhoneMsgIcon style={{fontSize: 30, color: "#0b3251"}} />
                    <p> +91 99674 90255 </p>
                </a>
              <a className='mt-4 d-flex' style={{padding:0, textDecoration : 'none', color: 'inherit'}} href='mailto:contact@droneschoolindia.com'>
                <Email fontSize="default" style={{ fontSize: 30, color: "#0b3251"}}/>
                <p> contact@droneschoolindia.com </p> 
              </a>  
            </ul>
            </Col>
            <Col xs={12} lg={6} className="rightCol">
              <ContactForm />
            </Col>
          </Row>
       </Container>


      </header>
    </div>
    </>
  );
}

export default Contact;
