import '../App.css';
import React, {useLayoutEffect} from 'react';
import {NavLink} from 'react-router-dom';
import './services.css';
import {Container , Row, Col,Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContactSection from '../HomeContactSection';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import LightDarkHeading from '../LightDarkHeading/LightDarkHeading';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation'
import HeaderVideo from '../videos/aerial-cinematics-header.mp4';
import dronegif from '../images/webp/Air2S.gif';
import Phantom4 from '../images/webp/p4.webp';
import Inspire1 from '../images/webp/inspire1_img.webp';
import Mavic2Pro from '../images/webp/mavic2.webp';
import MavicMini from '../images/webp/mavic-mini.webp';
import Air2S from '../images/webp/Air2S.webp';
import RealEstate from '../images/webp/Real-Estate-Card-Image.webp';
import FilmsCardImage from '../images/webp/Films-TV.webp';
import EventCardImage from '../images/webp/Event-Card-Image.webp';
import AdvertisementCardImage from '../images/webp/Advertisement.webp';
import {VideoGallery} from '../Gallery/AerialGallery';
import tourismCardImage from '../images/webp/tourism.webp';
import sportsCardImage from '../images/webp/sports.webp';
import showreelThumbnail1 from '../images/webp/showreel1-thumbnail.webp';
import showreelThumbnail4 from '../images/webp/showreel4-thumbnail.webp';
import aerialvideo1 from '../videos/GalleryVideos/aerial-video1.mp4';
import aerialvideo4 from '../videos/GalleryVideos/aerial-video4.mp4';


let aerialCinematographyIndusriesArray = [
    {   
        id : '1',
        src : RealEstate,
        title : 'Real Estate',
        info : 'Excite the prospective buyer with the help of majestic film showcasing the design and height of your new reality project. Make them envision their new life in a vast compound and all the amenities they will use when they move there.'
    },
    {
        id : '2',
        src : FilmsCardImage,
        title : 'Films and Music Videos',
        info : 'Excite the viewers and get them at the edge of their seats. Whether it is a chase scene across a city or the reveal of a mysterious tropical island, make your viewers part of the film by transporting them to the scene.'
    },
    {
        id : '3',
        src : EventCardImage,
        title : 'Outdoor Events',
        info : 'Provide visuals to your guests, concert goers and clients. Elevate the feel of your event by providing spectacular scenes as they occur live on the premises, and make them want for more.'
    },
    {
        
        id : '4',
        src : AdvertisementCardImage,
        title : 'Advertisements',
        info : 'Capture the consumer’s eye using engaging shots from various angles and providing a need to use the product. Whether it is a journey deep into a valley or the thrill of a sport that pumps adrenaline, make your product memorable by showing a lifestyle.'
    },
    {
        id : '5',
        src : tourismCardImage,
        title : 'Tourism and Travel',
        info : 'Win over tourists as the camera pans across the architecture, natural wonders and the history of your state. Take mesmerising shots of your key tourist points and create the need for travelers and adventurers to visit your state’s beauty.'
    },
    {
        id : '6',
        src : sportsCardImage,
        title : 'Sports Coverage',
        info :  'Be the master strategist for your favourite team as you view the game from the top. See every player’s position and enjoy the game from a different angle. Present live updates at your sporting events such as fundraising exhibitions and tournaments.'
    },
]


function HeroSection() {
    return(
        <>
            <header className="hero-header">
                <video autoPlay muted loop src={HeaderVideo} type="video/mp4"/>
                <div className="overlay">
                <h1 className='text-uppercase'> Aerial Cinematography</h1>
                <h4>A Fable From The Sky</h4>
                <br />
                <NavLink to="/aerial-video-gallery" className='text-decoration-none'><ButtonAnimation text='showreels' color='white'/></NavLink>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='get in touch' color='white'/></NavLink>
                </div>
            </header>
        </>
    )
}

function OverviewSection(){
    return(
        <>
            <Row className='overview-row'>
                    {/* <Col xs={12} lg={6} >
                    <div>
                        <video className='fit-image' controls controlsList="nodownload" poster={img1}>
                            <source src={HeaderVideo} />
                        </video>
                    </div>
                    </Col> */}
                    <Col xs={12} lg={12}>
                        <p className='content p-3'> Aerial Cinematography helps give your feature film the edge over the competition. Using high resolution cameras mounted on our drones, our expert drone pilots provide you the perfect shot from various points and angles inaccessible by a traditional camera, over any terrain or water. From shooting commercials of your new vehicle, as it is revealed by a cliff or showcase the height of your highrise development, where the camera pans to your skyscraper, aerial shots stimulate your senses by enriching the film.</p>
                    </Col>
                </Row>
        </>
    )
}

function IndustriesSection(props){
    let industriesArray = props.industries;
    return(
        <>
        <div className='industries-section'>
        <p className='heading industry-head'><HeaderUnderlineAnimation text='Industries'/> </p>
        <Row>
        {
            industriesArray.map((industryCard, index) => (
            <>
            <Col xs={12} lg={4}>
                <Card className='industry-card'>
                    <Card.Img src={industryCard.src} alt="industry icon" className='industry-img' />
                    <div class="industry-info">
                        <p>{industryCard.info}</p>
                    </div>
                    <Card.Body className='industry-card-body'>        
                        <div>
                        <h1><Card.Title className="text-uppercase mb-0"><HeaderUnderlineAnimation text={industryCard.title} lineColor='blue' /></Card.Title></h1>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            </>
            ))}
        </Row>
        </div>
        </>
    )
}


function DronesSection(){
    return(
        <>
        <div className='drones-section'>
            <p className='heading'><HeaderUnderlineAnimation text='Our Flying Beasts'/> </p>
        </div>

        <Row className='drones-row'>

            <Col xs={12} lg={4}>
                <Card className='drones-card'>
                    <Card.Img src={Air2S} alt="Air 2S" className='dronePhoto' />
                    <Card.Body className='industry-card-body'>        
                        <div>
                        <h1><Card.Title className="text-uppercase font-weight-bold mb-4">Air 2S</Card.Title></h1>
                        <p className='drone-description'>A full combo of compact, robust and 4K HD capabilities</p>
                        <div className='text-left'>
                        <ul className='drone-specs-list'>
                        <li><b>Operating Frequecy</b>:  2.4 GHz, 5.8 GHz</li>
                        <li><b>Max Ascent and Descent Speed </b>:  6 m/s and 6 m/s (S Mode)</li>
                        <li><b>Max Speed </b>: 19 m/s (S Mode)</li>
                        <li><b>Max Flight Time</b>: 30 minutes </li>
                        <li><b>Max Tilt Angle</b>: 30° (S Mode)</li>
                        <li><b>Camera Sensor</b>: 1" CMOS, 20 MP; 2.4μm Pixel Size </li>
                        <li><b>Video Resolution</b>: 5.4K: 5472×3078 @ 24/25/30 fps 4K Ultra HD: 3840×2160 @ 24/25/30/48/50/60 fps 2.7K: 2688x1512 @ 24/25/30/48/50/60 fps FHD: 1920×1080 @ 24/25/30/48/50/60/120 fps </li>
                        <li><b>Video Bitrate and Format</b>: 150 Mbps MP4/MOV (H.264/MPEG-4 AVC, H.265/HEVC)</li>

                        </ul>
                       
                        </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>


            <Col xs={12} lg={4}>
                <Card className='drones-card'>
                    <Card.Img src={Mavic2Pro} alt="Mavic 2 Pro" className='dronePhoto' />
                    <Card.Body className='industry-card-body'>        
                        <div>
                        <h1><Card.Title className="text-uppercase font-weight-bold mb-4">Mavic 2 Pro</Card.Title></h1>
                        <p className='drone-description'>The perfect blend of high-end technology & practicality.</p>
                        <div className='text-left'>
                        {/* <h5> FEATURES </h5> */}
                        <ul className='drone-specs-list'>
                        <li><b>Operating Frequecy</b>:  2.4 GHz, 5.8 GHz</li>
                        <li><b>Max Ascent and Descent Speed</b>: 5 m/s and 3 m/s (S Mode)</li>
                        <li><b>Max Speed</b>: 72 kmph</li>
                        {/* <li><b>Maximum Takeoff Altitude</b>: 6000 m</li> */}
                        <li><b>Max Flight Time</b>: 31 minutes</li>
                        <li><b>Max Tilt Angle</b>: 35° (S Mode)</li>
                        <li><b>Camera Sensor</b>: 1” CMOS, Effective Pixels: 20 million</li>
                        <li><b>Video Resolution</b>: 4K: 3840×2160 24/25/30p, 2.7K: 2688x1512 24/25/30/48/50/60p, FHD: 1920×1080 24/25/30/48/50/60/120p </li>
                        <li><b>Video Bitrate and Format</b>: 100Mbpss, MP4 / MOV (MPEG-4 AVC/H.264, HEVC/H.265)</li>

                        </ul>
                        {/* <h5> SUITABLE FOR </h5> */}
                        {/* <ul>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                        </ul> */}
                        </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>

            
            <Col xs={12} lg={4}>
                <Card className='drones-card'>
                    <Card.Img src={Inspire1} alt="industry img" className='dronePhoto' />
                    <Card.Body className='industry-card-body'>        
                        <div>
                        <h1><Card.Title className="text-uppercase font-weight-bold mb-4">Inspire 1</Card.Title></h1>
                        <p className='drone-description'>The best of the best Small Beast.</p>
                        <div className='text-left'>
                        {/* <h5> FEATURES </h5> */}
                        <ul className='drone-specs-list'>
                        <li><b>Operating Frequecy</b>:  2.4 GHz, 5.8 GHz</li>
                        <li><b>Max Ascent and Descent Speed</b>: 4 m/s and 4 m/s (S Mode)</li>
                        <li><b>Max Speed</b>: 79 kmph</li>
                        {/* <li><b>Max Service Ceiling Above Sea Level</b>: 2500 m</li> */}
                        <li><b>Max Flight Time</b>: 18 minutes</li>
                        <li><b>Max Tilt Angle</b>: 35° (S Mode)</li>
                        <li><b>Camera Sensor</b>: 1/2.3” CMOS, Effective pixels: 12.4M</li>
                        <li><b>Video Resolution</b>: UHD (4K): 4096x2160p24/25, 3840x2160p24/25/30
                        FHD: 1920x1080p24/25/30/48/50/60
                        HD: 1280x720p24/25/30/48/50/60 </li>
                        <li><b>Video Bitrate and Format</b>: 60Mbpss, MP4/MOV (MPEG-4 AVC/H.264)</li>
                        </ul>
                        {/* <h5> SUITABLE FOR </h5> */}
                        {/* <ul>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                            <li> Lorem Ipsum</li>
                        </ul> */}
                        </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>


        </>
    )
}

function AerialShowreels(){

    let videos =  [
        {   id :1,
            src: aerialvideo1,
            poster: showreelThumbnail1,
            lg : 6
        },
        {   id :2,
            src : aerialvideo4,
            poster: showreelThumbnail4,
            lg :6
        },
    ]


    // let videos = [video1, video1];
    return(
        <>
        <div className='courses-gallery-div'>
            <p className='heading mb-5'><HeaderUnderlineAnimation text='Showreels'/></p>
            <VideoGallery videoArray = {videos} />
        </div>
        
        </>
    )
}


function ExploreGallery (){
    return(
        <>
        <div className='explore-gallery-section'>
            <Row className='explore-gallery-content'>
                <Col xs={12} lg={6}>
                    <img src={dronegif} className='fit-image' alt='drone gif' />
                </Col>
                <Col xs={12} lg={6} >
                    <LightDarkHeading lightHeading='Showreels Gallery' darkHeading='Explore & Experience Places Virtually'/>
                    <ButtonAnimation  text='SHOWREELS' />
                </Col>
            </Row>
        </div>
        </>
    )
}


function AerialCinematography() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <div className='services-component'>
                <HeroSection />
                <Container>
                <OverviewSection />
                {/* <div  className='clientelle'>
                    <ClientsMarquee logo= {logo}/>
                </div> */}
                <IndustriesSection industries={aerialCinematographyIndusriesArray}/>
                <DronesSection />
                <AerialShowreels />
                <ExploreGallery />
                <HomeContactSection />
                </Container>
            </div>
        </>
    )}

export default AerialCinematography;
export {IndustriesSection}
